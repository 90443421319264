<template>
	<footer class="footer">
		<div class="footer__line l1">
			<div class="container-xl">
				<div class="row justify-content-sm-center justify-content-md-end">
					<div class="col-12 col-sm-9 col-md-6">
						<form-signup class="footer__form" />
					</div>
				</div>
			</div>
		</div>

		<div class="footer__line l2">
			<div class="container-xl">
				<div class="row align-items-md-center justify-content-md-between flex-md-row-reverse">
					<div class="col-12 col-md-auto">
						<img class="footer__logos img-fluid" src="@/assets/footer-logos.png" width="330" height="35" alt="logos" />
					</div>

					<div class="col-12 col-md-auto">
						<p class="footer__copy">DatingBiz {{ (new Date()).getFullYear() }} &copy; All rights reserved</p>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import FormSignup from '@/components/FormSignup.vue';

export default {
	name: 'c-footer',
	
	components: {
		'form-signup': FormSignup
	}
}
</script>

<style lang="scss">
.footer
{
	line-height: 1;
	text-align: center;

	&__line
	{
		position: relative;

		&.l1
		{
			padding-top: 70px;
			padding-bottom: 70px;

			&::before
			{
				content: '';
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: #343740 url('~@/assets/footer-bg.jpg') no-repeat right bottom;
				background-size: auto 100%;
				border-radius: 0 100px 0 0;
			}
		}

		&.l2
		{
			padding-top: 30px;
			padding-bottom: 30px;
			background-color: #343740;
		}
	}

	&__logos
	{
		
	}

	&__copy
	{
		margin-top: 25px;
		line-height: 1.2;
		font-size: 1.6rem;
		color: #F2F2F2;
	}

	@media (min-width: 576px)
	{
		
	}

	
	@media (max-width: 767px)
	{
		.checkfield
		{
			color: #FF5510;
		}
	}

	@media (min-width: 768px)
	{
		&__line
		{
			&.l1
			{
				padding-top: 100px;
				padding-bottom: 100px;

				&::before
				{
					right: 55%;
					border-radius: 0 150px 0 0;
				}
			}

			&.l2
			{
				padding-top: 25px;
				padding-bottom: 25px;
			}
		}

		&__copy
		{
			margin-top: 0;
		}
	}

	@media (min-width: 992px)
	{
		&__line
		{
			&.l1
			{
				padding-top: 140px;
				padding-bottom: 140px;
			}
		}
	}
}
</style>
