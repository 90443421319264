<template>
	<div class="hero align-items-end align-items-sm-center">
		<img
			class="hero__logo"
			width="223"
			src="@/assets/logo.png"
			srcset="@/assets/logo@2x.png 2x, @/assets/logo@3x.png 3x"
			alt="dating biz"
		/>

		<i class="hero__decor"></i>

		<div class="container-xl">
			<div class="row">
				<div class="col-12 col-sm-6 col-lg-5">
					<div class="hero__subtitle">We are ready to help you</div>

					<h1 class="hero__title">Let's brighten up your loneliness</h1>

					<p>Join a popular dating platform and end up with your loneliness. Plenty of beautiful Slavic girls want to meet someone like you.</p>

					<button type="button" class="custom-btn wide" @click="$emit('showModal')">Join us</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'hero',

	data: function ()
	{
		return {
			
		};
	}
}
</script>

<style lang="scss">
.hero
{
	position: relative;
	display: flex;
	min-height: 100vh;
	background: #FFF url('~@/assets/hero-bg.jpg') no-repeat 65% center;
	background-size: cover;
	padding: 50px 0;

	&__logo
	{
		position: absolute;
		top: 15px;
		left: 15px;
		z-index: 1;
	}

	&__decor
	{
		position: absolute;
		bottom: 70%;
		left: 69%;
		width: 500px;
		height: 500px;
		background-color: rgba(#FF5510, 0.5);
		border-radius: 50%;
	}

	&__title
	{
		padding: 5px 0;
		line-height: 1.1;
		font-size: 4rem;
		color: #333;
	}

	&__subtitle
	{
		line-height: 1.2;
		font-size: 1.8rem;
		text-transform: uppercase;
		color: #000;
	}

	.custom-btn
	{
		margin-top: 35px;
		max-width: 270px;
	}

	@media (min-width: 576px)
	{
		.custom-btn
		{
			margin-top: 55px;
		}
	}

	@media (min-width: 768px)
	{
		
	}

	@media (min-width: 992px)
	{
		&__title
		{
			font-size: 5rem;
		}
	}

	@media (min-width: 1200px)
	{
		&__logo
		{
			margin-left: calc((100% - 1170px) / 2);
		}
	}
}
</style>
